:root {
  --app-background: #ddf7ff;
  --page-header-height: 2rem;
  --page-header-second-row-height: 2rem;
  --page-header-background: #448199;
  --page-header-second-row-background: #366f85;
  --menu-header-background: #d6f5ff;
  --box-border-color: #94becd;
  --input-border-color: #aadfef;
  --box-border-radius: 5px;
  --page-header-z-index: 200;
  --margin-after-page-header: 2rem;
  --page-content-margin-top: calc(var(--page-header-height) + var(--margin-after-page-header));
  --page-content-margin-bottom: 2rem;
  --page-max-width: 800px;
  --menu-z-index: 100;
  --sticky-z-index: 50;
  --line-height: 1.4;
  --checkbox-size: 25px;
  --single-space-size: 5px;
  --checkbox-right-margin: 7px;
  --bulletpoint-size: 6px;
  /* --checkbox-size-with-margin: calc( */
  /*   var(--checkbox-size) + var(--checkbox-first-margin) + var(--checkbox-second-margin) */
  /* ); */
  --checkbox-accent: #197af8;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
}

html {
  line-height: var(--line-height);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
}

button,
input,
optgroup,
select,
textarea {
  line-height: var(--line-height);
}

body {
  background: var(--app-background);
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre,
blockquote,
table {
  margin: 0.5rem 0;
}

blockquote {
  margin-left: 2rem;
}

ul ol,
ol ul,
ul ul,
ol ol {
  margin: 0.25rem 0;
}
li + li {
  margin-top: 0.25rem;
}

button {
  background: var(--app-background);
  border: 1px solid #448199;
  padding: 0.5rem;
  border-radius: 5px;
  transition: 0.2s background ease-in-out;
  color: #000;

  &:hover {
    background: #cfecf5;
  }

  &:focus-visible {
    outline: 1px solid #448199;
  }

  &.primary {
    background: #448199;
    color: #fff;

    &:hover {
      background: #3e778e;
    }

    /* &:focus-visible { */
    /* outline: 1px solid #448199; */
    /* } */
  }

  &.button-row {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    /* width: 400px; */

    @media (max-width: 800px) {
      border-radius: 0;
      width: 100%;
    }
  }
}

/* p { */
/* margin: 0; */
/* } */

/* p + p { */
/*   margin-top: 1rem; */
/* } */

.text-input {
  /* width: 500px; */
  /* margin: 0 auto; */
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  outline: none;
  padding: 0.5rem 0.75rem;

  &:not(.small) {
    @media (max-width: 800px) {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}

/* .file { */
/*   position: relative; */
/*   display: inline-block; */
/*   cursor: pointer; */
/*   height: 2.5rem; */
/*   text-align: left; */

/*   & input { */
/*     min-width: 14rem; */
/*     margin: 0; */
/*     opacity: 0; */
/*   } */
/*   & .file-custom { */
/*     position: absolute; */
/*     top: 0; */
/*     right: 0; */
/*     left: 0; */
/*     z-index: 5; */
/*     height: 1.5rem; */
/*     padding: 0.5rem 1rem; */
/*     line-height: 1.5; */
/*     color: #555; */
/*     background-color: #fff; */
/*     border: 1px solid var(--input-border-color); */
/*     /\* border: 0.075rem solid #ddd; *\/ */
/*     /\* border-radius: 0.25rem; *\/ */
/*     outline: none; */
/*     border-radius: 5px; */
/*     /\* box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05); *\/ */
/*     user-select: none; */
/*     overflow: hidden; */

/*     &::before { */
/*       position: absolute; */
/*       top: -0.075rem; */
/*       right: -0.075rem; */
/*       bottom: -0.075rem; */
/*       z-index: 6; */
/*       display: block; */
/*       content: 'Browse'; */
/*       height: 1.5rem; */
/*       padding: 0.5rem 1rem; */
/*       line-height: 1.5; */
/*       color: #333; */
/*       background-color: white; */
/*       border: 0.075rem solid #ddd; */
/*       border-radius: 0 0.25rem 0.25rem 0; */
/*     } */

/*     .custom-label { */
/*       white-space: nowrap; */
/*     } */
/*   } */
/* } */

.page {
  flex: 1;
  margin: var(--page-content-margin-top) auto var(--page-content-margin-bottom) auto;
  max-width: var(--page-max-width);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

h2.page-message {
  margin-top: 3rem;
  text-align: center;
  color: #666;
}

a.reset {
  &,
  &:visited,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}
