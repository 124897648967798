.notes-page {
  .new-note-container {
    display: flex;
    flex-direction: column;
    transition: 0.15s transform ease-out;

    &.below-second-row {
      transform: translate3d(
        0,
        calc((var(--margin-after-page-header) * 2 - var(--page-header-second-row-height)) / 2),
        0
      );
    }

    &.sticky {
      position: sticky;
      top: var(--page-header-height);
      z-index: var(--sticky-z-index);
      overflow: visible;

      &.below-second-row {
        transform: none;
        top: calc(var(--page-header-height) + var(--page-header-second-row-height));
        transition: none;
      }

      .editor {
        /* more padding to clear the top where messages are shown. */
        /* min-height: 100px; */
        box-shadow: 5px 5px 14px 7px #00000022;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        max-height: 80vh;

        /* NOTE Chaning the size of the editor causes jumps on scroll position restoration during page transitions */
        /* padding-top: 1.2rem; */
        /* padding-bottom: 1.2rem; */
      }

      /* IOS Safari doesn't show the shadow if it's on .editor */
      @media (max-width: 800px) {
        box-shadow: 5px 5px 14px 7px #00000022;
        .editor {
          box-shadow: none;
        }
      }
    }

    &.invisible {
      opacity: 0;
      pointer-events: none;
    }

    &:not(.sticky) .editor:focus {
      outline: 2px solid #aadfef;
    }

    .editor {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .notes {
    margin-top: var(--margin-after-page-header);
  }

  & button.load-more {
    margin-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
