.login-page {
  max-width: 300px;

  .form-element {
    display: flex;
    flex-direction: column;

    & + .form-element {
      margin-top: 0.5rem;
    }
    label {
      /* width: 80px; */
    }
    input[type='text'] {
      margin-top: 0.25rem;
    }
    input[type='checkbox'] {
      vertical-align: middle;
    }

    .strength {
      font-size: 0.9em;
      margin-left: 0.5rem;
    }
  }

  .buttons {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    & button.login {
      /* max-width: 150px; */
      /* width: 100%; */
      /* margin-left: auto; */
      /* margin-right: auto; */
    }
  }

  .section {
    margin-top: 2rem;
    border-top: 1px solid #aadfef;
    padding-top: 1rem;
    & p + p {
      margin: 1rem;
    }
  }

  .welcome {
    text-align: center;
  }

  .storage-message {
    text-align: center;
    button {
      width: 100%;
    }
  }
}
