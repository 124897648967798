.note-page {
  margin-bottom: 0;

  .note-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .editor {
      flex: 1;
      padding: 1rem;
    }
    .footer {
      padding: 0.75rem 1rem;
      font-size: 0.6em;
      opacity: 0.6;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }
  }
}
