.notes {
  border: 1px solid var(--box-border-color);
  border-radius: var(--box-border-radius);
  overflow: hidden;

  @media (max-width: 800px) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  /* Show selection circles if a selectable note is hovered. */
  &.selectable .note:hover {
    @media (min-width: 800px) {
      .select {
        visibility: visible;
      }
      & img.pin {
        visibility: hidden;
      }
    }
  }

  /* Show selection circles if selection mode is on. */
  &.has-selection .note {
    .select {
      visibility: visible;
    }
    & img.pin {
      visibility: hidden;
    }
  }

  .note {
    padding: 0.2rem 1rem; /* The vertical padding accounts for the line-height of paragraphs and headings */
    transition: 0.2s background ease-in-out;
    font-family: inherit;
    /* word-break: break-word; */
    /* word-wrap: break-word; */
    /* line-height: var(--note-line-height); */
    /* margin: 0; */
    position: relative;
    display: flex; /* This is to disable margin collapse */
    flex-direction: column;
    min-height: 2rem;

    &.clickable {
      cursor: pointer;
      &:hover {
        background: #d0ebf5;
      }
    }

    &.selected {
      .select {
        visibility: visible;
      }
      & img.pin {
        visibility: hidden;
      }
    }

    &.pinned {
      /* border-left: 3px solid var(--box-border-color); */
      /* &:first-child { */
      /*   border-radius: var(--box-border-radius) var(--box-border-radius) 0 0; */
      /* } */
      /* &:last-child { */
      /*   border-radius: 0 0 var(--box-border-radius) var(--box-border-radius); */
      /* } */
    }

    & + .note {
      border-top: 1px solid var(--box-border-color);
    }

    & .select {
      --select-padding: 6.885px;
      position: absolute;
      top: 0;
      right: 0;
      /* width: calc(30px + var(--select-padding) * 2); */
      /* height: calc(30px + var(--select-padding) * 2); */
      padding: var(--select-padding);
      visibility: hidden;

      &:hover,
      &:focus,
      &:focus-visible,
      &:active {
        outline: none;
        border: none;
        background: none;

        .circle {
          box-shadow: 0px 0px 10px 0px #0000002e;
          transition: 0.1s all ease-in-out;
        }
      }

      &.selected .circle {
        border: 1px solid #888;
        box-shadow: none;
      }

      &:not(.selected) .circle img {
        display: none;
      }

      .circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #ccc;
        position: relative;
        /* box-shadow: 0 0 3px 0px #ccc inset; */
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          margin-top: 3px;
        }
      }
    }

    & img.pin {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 20px;
      opacity: 0.5;
    }

    & .empty {
      opacity: 0.5;
      font-style: italic;
    }

    & hr {
      margin: 1rem 0;
      border-color: var(--box-border-color);
      border-style: dashed;
      opacity: 0.5;
    }

    & table {
      border: 1px dashed var(--box-border-color);
      border-collapse: collapse;
    }

    & th,
    td {
      padding: 0.25rem 0.5rem;
      border: 1px dashed var(--box-border-color);
    }

    & pre {
      background: #00000005;
      padding: 0.5rem;
      white-space: pre-wrap;
    }
    & blockquote {
      background: #00000005;
      padding: 0 0.5rem;
    }

    & table {
      background: #00000005;
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    & h1 {
      font-size: 1.1rem;
    }

    & h2 {
      font-size: 1rem;
    }

    & h3,
    h4,
    h5,
    h6 {
      font-size: 1rem;
    }

    & ul {
      padding-inline-start: calc(var(--checkbox-size) + var(--single-space-size) + var(--checkbox-right-margin));

      & > li.task-list-item {
        list-style-type: none;
        /* &::marker { */
        /*   content: ''; */
        /* } */

        & > input[type='checkbox'],
        & > p > input[type='checkbox'] {
          margin-left: calc(-1 * (var(--checkbox-size) + var(--single-space-size) + var(--checkbox-right-margin)));
        }
      }

      & > li:not(.task-list-item) {
        position: relative;
        list-style-type: none;
        /* &::marker { */
        /*   content: ''; */
        /* } */

        &:empty {
          height: 1.5rem;
        }

        &:before {
          content: '';
          /* float: left; */
          /* display: list-item; */
          /* list-style-type: circle; */
          /* list-style-position: inside; */
          /* width: 20px; */
          /* font-size: 1.5rem; */
          /* line-height: 0.8; */
          /* vertical-align: middle; */
          /* width: 1px; */
          left: calc(
            -1 * (var(--checkbox-size) / 2 + var(--single-space-size) + var(--checkbox-right-margin) +
                  var(--bulletpoint-size) / 2 + 1px)
          );
          width: var(--bulletpoint-size);
          height: var(--bulletpoint-size);
          border-radius: 50%;
          border: 2px solid #444;
          position: absolute;
          top: 6px;
        }
      }
    }

    & ol {
      padding-inline-start: calc(var(--checkbox-size) + var(--single-space-size));

      & > li.task-list-item {
        padding-left: calc(var(--checkbox-size) + var(--single-space-size) + var(--checkbox-right-margin) + 6px);

        & > input[type='checkbox'],
        & > p > input[type='checkbox'] {
          margin-left: calc(
            var(--single-space-size) + 2px - var(--checkbox-size) - var(--single-space-size) -
              var(--checkbox-right-margin) - 6px
          );
        }
      }
    }

    /* & ol { */
    /*   padding-inline-start: calc(var(--checkbox-size) + var(--checkbox-first-margin)); */
    /* } */

    /*
    & ul {
      padding-inline-start: var(--checkbox-size-with-margin);
    }

    & ol {
      padding-inline-start: calc(var(--checkbox-size) + var(--checkbox-first-margin));
    }

    & ul > li.task-list-item {
      text-indent: calc(-1 * var(--checkbox-size-with-margin));

      &::marker {
        content: '';
      }
    }

    & ol > li.task-list-item > input[type='checkbox'] {
      margin-left: var(--checkbox-second-margin);
    }
    */

    & input[type='checkbox'] {
      border: 1px solid #aadfef;
      border-radius: 5px;
      outline: none;
      padding: 0.5rem 0rem;
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      vertical-align: middle;
      margin: 0;
      margin-top: -2px;
      accent-color: var(--checkbox-accent);
      margin-right: var(--checkbox-right-margin);
    }
  }
}
